<template>
  <div>
    <pdf
      v-for="i in numPages"
      :key="i"
      :src="src"
      :page="i"
      style="display: inline-block; width: 100%"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import pdf from 'vue-pdf'
// pdf.createLoadingTask('/pdf/Publicity.pdf');
const loadingTask = ''
export default {
  name: 'CreditCardPDF',
  components: {
    pdf
  },
  data() {
    return {
      src: loadingTask,
      numPages: undefined
    }
  },
  mounted() {
    Toast.loading({
      duration: 0, // 持续展示 toast
      message: 'PDF加载中...',
      forbidClick: true
    })
    this.src.promise.then(pdf => {
      this.numPages = pdf.numPages
      Toast.clear()
    })
  }
}
</script>

<style lang="less" scoped>
html,
body,
#app,
.content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
